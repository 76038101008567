<template>
  <div class="chosen-filter">
    <h2 class="chosen-filter__title">Chosen Filter</h2>
    <div class="chosen-filter__wrap">
      <h3 class="chosen-filter__subtitle">Chosen Filter Default</h3>
      <ChosenFilter
        :chosenList="chosenList"
        :value="value"
        v-model="value"
        :icon="'error-secondary'"
        :placeholder="'Критичность'"
      />
    </div>
    <div class="chosen-filter__wrap">
      <h3 class="chosen-filter__subtitle">Chosen Filter Props</h3>
      <ul class="chosen-filter__list">
        <li class="chosen-filter__item"><span>chosenList</span> - массив пунктов Chosen Filter</li>
        <li class="chosen-filter__item"><span>value</span> - выбранное значение</li>
        <li class="chosen-filter__item"><span>icon</span> - имя иконки</li>
        <li class="chosen-filter__item">
          <span>unique</span> - уникальное значение для срабатывания emit
        </li>
        <li class="chosen-filter__item">
          <span>placeholder</span> - текст внутри Chosen Filter, без выбранного значения
        </li>
      </ul>
    </div>
    <div class="chosen-filter__wrap">
      <h3 class="chosen-filter__subtitle">Chosen Filter Multiple</h3>
      <ChosenFilter
        :chosenList="chosenListMultiple"
        :value="valueMultiple"
        v-model="valueMultiple"
        :search="true"
        :icon="'error-secondary'"
        :placeholder="'Критичность'"
        :multiple="true"
      />
    </div>
  </div>
</template>

<script>
import ChosenFilter from "@/modules/UiKit/components/ChosenFilter/ChosenFilter";
export default {
  name: "ChosenFilterPage",
  mixins: [],
  props: [],
  components: { ChosenFilter },
  data() {
    return {
      chosenList: [
        {
          name: "Комфорт",
          id: 1
        },
        {
          name: "Значительная",
          id: 2
        },
        {
          name: "Критическая",
          id: 3
        },
        {
          name: "Блокирующая",
          id: 4
        }
      ],
      value: [],
      chosenListMultiple: [
        {
          name: "Комфорт",
          id: 1
        },
        {
          name: "Значительная",
          id: 2
        },
        {
          name: "Критическая",
          id: 3
        },
        {
          name: "Блокирующая",
          id: 4
        }
      ],
      valueMultiple: []
    };
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";

.chosen-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__title {
    width: 100%;
    margin: 0 0 24px;
    color: $text-default;

    &_textarea {
      margin-top: 48px;
    }
  }

  &__subtitle {
    margin: 0 0 16px;
    padding: 0 0 16px;
    border-bottom: 1px solid $border-disabled;
    color: $text-default;
  }

  &__content {
    margin: 24px 0 48px;
  }

  &__wrap {
    width: 48%;
    margin: 24px 0;

    &_default {
      width: 100%;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 0 0 8px;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;

    span {
      font-weight: bold;
    }
  }
}
</style>
